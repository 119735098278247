<template>
  <g>
    <svg:style>
      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#inox-gradient-3);
      }

      .cls-7 {
        fill: url(#inox-gradient-4);
      }

      .cls-8,
      .cls-9,
      .cls-10,
      .cls-11 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 38.84"
                    :y1="doorTopHeight1 + 170.92"
                    :x2="doorLeftWidth1 + 102.47"
                    :y2="doorTopHeight1 + 170.92"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-2"
                    data-name="inox"
                    :x1="doorLeftWidth1 + 38.84"
                    :y1="doorTopHeight1 + 213.34"
                    :x2="doorLeftWidth1 + 102.47"
                    :y2="doorTopHeight1 + 213.34"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-3"
                    data-name="inox"
                    :x1="doorLeftWidth1 + 38.84"
                    :y1="doorTopHeight1 + 128.5"
                    :x2="doorLeftWidth1 + 102.47"
                    :y2="doorTopHeight1 + 128.5"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-4"
                    data-name="inox"
                    :x1="doorLeftWidth1 + 38.84"
                    :y1="doorTopHeight1 + 86.08"
                    :x2="doorLeftWidth1 + 102.47"
                    :y2="doorTopHeight1 + 86.08"
                    xlink:href="#handle-gradient"/>

    <polyline id="inox"
              v-if="showInox"
              data-name="inox"
              class="cls-4"
              :points="`
                ${doorLeftWidth1 + 38.84} ${doorTopHeight1 + 181.52}
                ${doorLeftWidth1 + 102.47} ${doorTopHeight1 + 181.52}
                ${doorLeftWidth1 + 102.47} ${doorTopHeight1 + 160.31}
                ${doorLeftWidth1 + 38.84} ${doorTopHeight1 + 160.31}
                ${doorLeftWidth1 + 38.84} ${doorTopHeight1 + 181.52}
              `"/>
    <polyline id="inox-2"
              v-if="showInox"
              data-name="inox"
              class="cls-5"
              :points="`
                ${doorLeftWidth1 + 38.84} ${doorTopHeight1 + 223.94}
                ${doorLeftWidth1 + 102.47} ${doorTopHeight1 + 223.94}
                ${doorLeftWidth1 + 102.47} ${doorTopHeight1 + 202.73}
                ${doorLeftWidth1 + 38.84} ${doorTopHeight1 + 202.73}
                ${doorLeftWidth1 + 38.84} ${doorTopHeight1 + 223.94}
              `"/>
    <polyline id="inox-3"
              v-if="showInox"
              data-name="inox"
              class="cls-6"
              :points="`
                ${doorLeftWidth1 + 102.47} ${doorTopHeight1 + 117.9}
                ${doorLeftWidth1 + 38.84} ${doorTopHeight1 + 117.9}
                ${doorLeftWidth1 + 38.84} ${doorTopHeight1 + 139.1}
                ${doorLeftWidth1 + 102.47} ${doorTopHeight1 + 139.1}
                ${doorLeftWidth1 + 102.47} ${doorTopHeight1 + 117.9}
              `"/>
    <polyline id="inox-4"
              v-if="showInox"
              data-name="inox"
              class="cls-7"
              :points="`
                ${doorLeftWidth1 + 102.47} ${doorTopHeight1 + 75.48}
                ${doorLeftWidth1 + 38.84} ${doorTopHeight1 + 75.48}
                ${doorLeftWidth1 + 38.84} ${doorTopHeight1 + 96.69}
                ${doorLeftWidth1 + 102.47} ${doorTopHeight1 + 96.69}
                ${doorLeftWidth1 + 102.47} ${doorTopHeight1 + 75.48}
              `"/>
    <polyline filter="url(#inset-shadow)"
              id="glass"
              class="cls-8"
              :points="`
                ${doorLeftWidth1 + 98.22} ${doorTopHeight1 + 79.78}
                ${doorLeftWidth1 + 43.08} ${doorTopHeight1 + 79.78}
                ${doorLeftWidth1 + 43.08} ${doorTopHeight1 + 92.51}
                ${doorLeftWidth1 + 98.22} ${doorTopHeight1 + 92.51}
                ${doorLeftWidth1 + 98.22} ${doorTopHeight1 + 79.78}
              `"/>
    <polyline filter="url(#inset-shadow)"
              id="glass-2"
              data-name="glass"
              class="cls-9"
              :points="`
                ${doorLeftWidth1 + 98.22} ${doorTopHeight1 + 122.2}
                ${doorLeftWidth1 + 43.08} ${doorTopHeight1 + 122.2}
                ${doorLeftWidth1 + 43.08} ${doorTopHeight1 + 134.93}
                ${doorLeftWidth1 + 98.22} ${doorTopHeight1 + 134.93}
                ${doorLeftWidth1 + 98.22} ${doorTopHeight1 + 122.2}
              `"/>
    <polyline filter="url(#inset-shadow)"
              id="glass-3"
              data-name="glass"
              class="cls-10"
              :points="`
                ${doorLeftWidth1 + 98.22} ${doorTopHeight1 + 164.62}
                ${doorLeftWidth1 + 43.08} ${doorTopHeight1 + 164.62}
                ${doorLeftWidth1 + 43.08} ${doorTopHeight1 + 177.35}
                ${doorLeftWidth1 + 98.22} ${doorTopHeight1 + 177.35}
                ${doorLeftWidth1 + 98.22} ${doorTopHeight1 + 164.62}
              `"/>
    <polyline filter="url(#inset-shadow)"
              id="glass-4"
              data-name="glass"
              class="cls-11"
              :points="`
                ${doorLeftWidth1 + 98.22} ${doorTopHeight1 + 207.04}
                ${doorLeftWidth1 + 43.08} ${doorTopHeight1 + 207.04}
                ${doorLeftWidth1 + 43.08} ${doorTopHeight1 + 219.76}
                ${doorLeftWidth1 + 98.22} ${doorTopHeight1 + 219.76}
                ${doorLeftWidth1 + 98.22} ${doorTopHeight1 + 207.04}
              `"/>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    }
  },
}
</script>
